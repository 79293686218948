import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import styled from "styled-components"; // Import styled
import { supabase } from "../lib/supabase";

const SUPABASE_EDGE_API_URL = "https://veuavewgqwdvymophomy.supabase.co/functions/v1/apis"
// const SUPABASE_EDGE_API_URL = "http://127.0.0.1:54321/functions/v1/apis"
const SUPABASE_ANON_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZldWF2ZXdncXdkdnltb3Bob215Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDYwNjE4ODgsImV4cCI6MjAyMTYzNzg4OH0.qPhIulX2xw5IEm01x5m3b7RvBFSKH8NgUC5JjEh2oI8"

const StyledTextarea = styled.textarea`
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px;
  border: 2px solid  #973e95;
  outline: none;
  resize: none;
  border-radius: 4px;
`;

const StyledButton = styled.button`
  margin-top: 8px;
  padding: 8px 16px;
  background-color: #973e95;
  color: black;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  width: 30%;
  margin-right: auto;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


const NoteForm = ({ onNoteAdded }) => {
  const [noteText, setNoteText] = useState("");
  const { userProfile } = useAuth();

  const handleNoteSave = async () => {
    const textToInsert = noteText.trim()
    if (textToInsert !== "") {
      console.log("Adding note note")
      console.log("user profile is", userProfile)
      var noteData = {
        text: textToInsert,
        created_at: Math.floor(Date.now() / 1000),
        user_id: userProfile?.id
      }
      try {
        const { data, error } = await supabase.from("notes").insert(noteData).select("id").single()
        if (error) {
          throw error
        } else {
          console.log("Insertion result is", data)
          const noteId = data?.id;
          if (data?.id) {
            noteData.id = noteId;
            onNoteAdded(noteData);
            setNoteText("")
          }
          // Save note embedding
          const url = `${SUPABASE_EDGE_API_URL}/embed_note`;
          console.log("Sending note to embedding", url)
          const embedResponse = await fetch(url, {
            method: 'POST',
            headers: {
              'Accept': '*/*',
              'Authorization': `Bearer ${SUPABASE_ANON_KEY}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              note_id: noteId,
              note_text: textToInsert,
            }),
          });

          if (embedResponse.ok) { // If HTTP status code is 200-299
            const embedResponseJson = await embedResponse.json();
            console.log("Note embeded:", embedResponseJson)
          } else {
            console.error(`Failed to embed note: ${embedResponse.status}`);
          }
        }
      } catch (error) {
        console.error("Error adding note:", error);
      }
    }
  };
  

  return (
    <StyledContainer>
      <StyledTextarea
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
        rows="4"
        cols="50"
        placeholder="What's your 💡?"
      ></StyledTextarea>
      <StyledButton onClick={handleNoteSave}>✍🏼</StyledButton>
    </StyledContainer>
  );
};

export default NoteForm;
