import React from "react";
import { USER_DATA } from "../constants";
import { useAuth } from "../components/AuthContext";
import "./PersonalCenter.css"
import { supabase } from "../lib/supabase";

export const PersonalCenter = () => {
  const {onLogout } = useAuth();

  return (
    <div className="page-container personal-center-container">
        <h2>Personal Center</h2>
        <p>Welcome to your personal center! You can manage your account settings here.</p>
        <button className="logout-button" onClick={async () => {
          const { error } = await supabase.auth.signOut()
          if (error) {
            console.log("Failed to logout with error", error)
          } else {
            onLogout()
          }
        }}>Logout</button>
    </div>
  );
};