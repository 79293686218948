// AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { USER_DATA } from '../constants';

// Create an authentication context
const AuthContext = createContext();

// Create a provider component that manages authentication state
export function AuthProvider({ children }) {
    const [userProfile, setUserProfile] = useState(null); // Initialize with no user

    // Define authentication functions like login and logout
    const onLogin = (newUserProfile) => {
        setUserProfile(newUserProfile)
    };
    const onLogout = () => {
        setUserProfile(null)
    };

    return (
        <AuthContext.Provider value={{ userProfile, onLogin, onLogout }}>
            {children}
        </AuthContext.Provider>
    );
}

// Create a custom hook for accessing the authentication context
export function useAuth() {
  return useContext(AuthContext);
}
