import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation, useNavigate } from "react-router-dom"
import './Navbar.css'; // Import the CSS


const Navbar = () => {
  const { userProfile } = useAuth();
  const navigate = useNavigate();

  const renderRightContent = () => {
    if (userProfile) {
      // If the user is logged in, display a person icon and a logout button
      return (
        <>
          <PersonIcon style={{ color: 'black', cursor: "pointer"}} onClick={() => {
            navigate("/personal")
          }}/>
        </>
      );
    } else {
      // If the user is not logged in, display login and register buttons
      // return (
      //   <>
      //     <Link to="/login">Login</Link>
      //     <Link to="/register">Register</Link>
      //   </>
      // );
      return null
    }
  };

  return (
    <nav>
      <div id="content-container">
        <div className="left-content" onClick={() => {navigate("/")}}>
          {/* <img src="/icon.png" alt="Your Logo" className="logo" /> */}
          <span className="app-name">sparknote</span>
        </div>
        <div className="right-content">{renderRightContent()}</div>
      </div>
    </nav>
  );
};

export default Navbar;
