import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { Landing } from "./pages/Landing";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { PersonalCenter } from "./pages/PersonalCenter";
import NoteList from "./components/NoteList";
import { AuthProvider, useAuth } from './components/AuthContext'; // Import the AuthProvider
import Navbar from "./components/Navbar";
import { supabase } from "./lib/supabase";

import "./App.css"

function App() {
  return (
    <AuthProvider>
      <Main />
    </AuthProvider>
  );
}

function Main() {
  const [session, setSession] = useState(null);
  // const [submitGptModalIsVisible, setSubmitGptModalIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const {userProfile, onLogin} = useAuth();

  useEffect(() => {
    console.log("calling use effect")
    const checkAuth = async () => {
      // Check auth status
      const { data } = await supabase.auth.getSession();
      const currentUser = data?.session?.user
      var profileData = {}
      onLogin(currentUser)
    }

    checkAuth()
  }, []);

  if (loading) {
    console.log("loading")
  } else {
    console.log("not loading")

  }

  return (
    <Router>
      <Navbar />
        <Routes>
          <Route path="/" element={userProfile ? <NoteList /> : <Landing />} />
          {/* <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} /> */}

          {userProfile ? (
            <>
              <Route path="/personal" element={<PersonalCenter />} />
              <Route path="/notes" element={<NoteList />} />
            </>
          ) : (
            // Redirect to /login if not authenticated
            <Route path="/*" element={<Navigate to="/login" />} />
          )}
        </Routes>
    </Router>
  );
}

export default App;
