import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components"; // Import styled
import { supabase } from "../lib/supabase";
import NoteForm from "./NoteForm";

const StyledNoteList = styled.div`
  margin: 20px auto auto auto;
  height: 100%;
  minWidth: 100px;
  maxWidth: 400px;
  width: 800px;
  display: flex;
  flex-direction: column;
`;

const NoteItem = styled.div`
  padding: 20px;
  margin: 20px 0;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: SourceHanSerif-Light;
  background-color: white;
  border-radius: 8px;
`;

const NoteList = () => {
  const [notes, setNotes] = useState([])
  var noteComponents = []
  var lastDate = null;
  var index = 0;

  useEffect(() => {
    const fetchNotes = async () => {
      const {data, error } = await supabase.from("notes").select("text, created_at, id").order('created_at', { ascending: false })
      if (error) {
        console.log("Failed to fetch notes with error", error)
      } else {
        console.log("Fetched data number", data.length)
        setNotes(data)
      }
    }
    fetchNotes()
  }, [])

  for (const note of notes) {
    const timestamp = note.created_at * 1000; // Original unit is seconds
    if (!timestamp) {
      console.log("note with empty timestamp is", note.text)
    }
    const dateFromTimestamp = new Date(timestamp);

    const day = String(dateFromTimestamp.getDate()).padStart(2, '0');
    const month = String(dateFromTimestamp.getMonth() + 1).padStart(2, '0');
    const year = dateFromTimestamp.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    if ((!lastDate) || (formattedDate !== lastDate)) {
      lastDate = formattedDate
      noteComponents.push(<p key={index} style={{"fontSize": "20px", "fontFamily": "SourceHanSerif-Bold"}}>{formattedDate}</p>)
      index += 1
    }
    noteComponents.push(<NoteItem key={index}>{note.text}</NoteItem>)
    index+=1;
  }
  const onNoteAdded = (newNote) => {
    setNotes([
      newNote,
      ...notes
    ])
  }
  return (
    <StyledNoteList id="notes">
      <NoteForm onNoteAdded={onNoteAdded}/>
      {noteComponents}
    </StyledNoteList>
  );
};

export default NoteList;
