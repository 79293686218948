import React from "react";
import { Login } from "./Login";

export const Landing = () => {
  return (
    <div className="page-container">
      {/* <p>Please log in or register to access the full features of our platform.</p> */}
      <Login />
    </div>
  );
};
