import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useAuth } from '../components/AuthContext';

export const Login = () => {
    const navigate = useNavigate();
    const {onLogin, onLogout} = useAuth();
  
    useEffect(() => {
      // Listen for auth changes on:
      // SIGNED_IN, SIGNED_OUT, TOKEN_REFRESHED, USER_UPDATED, USER_DELETED, PASSWORD_RECOVERY
      const { subscription } = supabase.auth.onAuthStateChange(
          async (event, session) => {
            console.log("authentication event changed", event, "new session is", session?.access_token)
            // Update user session
            
            if (event === 'SIGNED_IN') {
              onLogin(session?.user)
            } else if (event === 'SIGNED_OUT') {
              onLogout()
            }
            navigate('/');
          }
      );
      // Unsubscribe on cleanup
      return () => {
        subscription?.unsubscribe();
      };
    }, [navigate]);
  
    return (
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
          // providerScopes={{
          //   google: 'https://www.googleapis.com/auth/calendar.readonly',
          // }}
        />
    );
  };