import { createClient } from '@supabase/supabase-js';

// Initialize your Supabase client
// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseUrl = "https://veuavewgqwdvymophomy.supabase.co";
console.log("supabase url is", supabaseUrl)
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZldWF2ZXdncXdkdnltb3Bob215Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDYwNjE4ODgsImV4cCI6MjAyMTYzNzg4OH0.qPhIulX2xw5IEm01x5m3b7RvBFSKH8NgUC5JjEh2oI8";
console.log("anon key is", supabaseAnonKey)
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
})